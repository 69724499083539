import React from 'react';
import { Button, Grid, Icon, Form, Header, Segment } from 'semantic-ui-react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import './signup.less';

const SignupPage = () => (
  <Layout>
    <SEO
      title="Sign Up"
      keywords={[`gatsby`, `application`, `react`, `training`]}
      description="Add your name to our list and be the first to know when we add more Node.js-specific guides and tutorials"
    />
    <Grid centered columns={12}>
      <Grid.Row>
        <Grid.Column computer={9} tablet={12} mobile={12}>
          <Header as="h1" data-drip-attribute="headline">
            Join the Hey Node mailing list
          </Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="signup">
        <Grid.Column computer={4} tablet={6} mobile={12}>
          <Segment>
            <Form
              action="https://www.getdrip.com/forms/662782570/submissions"
              method="post"
              data-drip-embedded-form="662782570"
            >
              <Form.Input
                type="email"
                id="drip-email"
                name="fields[email]"
                label="Email"
              />
              <Form.Input
                type="text"
                id="drip-first-name"
                name="fields[first_name]"
                label="First name"
              />
              <Form.Input
                type="text"
                id="drip-last-name"
                name="fields[last_name]"
                label="Last name"
              />
              <Form.Field>
                <input
                  type="hidden"
                  name="fields[eu_consent]"
                  id="drip-eu-consent-denied"
                  value="denied"
                />
                <Form.Checkbox
                  name="fields[eu_consent]"
                  id="drip-eu-consent"
                  value="granted"
                  inline
                  label={{
                    children: (
                      <span>
                        I consent to direct email marketing from Osio Labs, in
                        accordance with their
{' '}
                        <a href="https://osiolabs.com/privacy">
                          privacy policy
                        </a>
                        .
                      </span>
                    ),
                  }}
                />
              </Form.Field>

              <Form.Field>
                <input
                  type="hidden"
                  name="fields[eu_consent_message]"
                  value="I consent to direct email marketing from Osio Labs, in accordance with their privacy policy (https://osiolabs.com/privacy)."
                />
              </Form.Field>

              <div style={{ display: 'none' }} aria-hidden="true">
                <Form.Field>
                  <label htmlFor="website">Website</label>
                  <br />
                  <input
                    type="text"
                    id="website"
                    name="website"
                    tabIndex="-1"
                    autoComplete="false"
                  />
                </Form.Field>
              </div>

              <div>
                <Button
                  icon
                  labelPosition="left"
                  secondary
                  type="submit"
                  data-drip-attribute="sign-up-button"
                >
                  <Icon name="right arrow" />
                  Sign Up
                </Button>
              </div>
            </Form>
          </Segment>
        </Grid.Column>
        <Grid.Column computer={1} tablet={1} mobile={0} />
        <Grid.Column computer={4} tablet={5} mobile={12}>
          <div className="description" data-drip-attribute="description">
            <p>
              <a href="https://osiolabs.com/" rel="nofollow">
                Osio Labs
              </a>
              {' '}
              is ramping up our Node.js training with Hey Node!
            </p>
            <p>
              Add your name to our list and be the first to know when we add more Node.js-specific guides and tutorials - in both written and video formats.
            </p>
            <p>
              Opting in means you'll occasionally receive newsletters and
              updates. Opt out any time by clicking the unsubscribe link in the
              email footer, or contacting us at info@osiolabs.com.
            </p>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Layout>
);

export default SignupPage;
